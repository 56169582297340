body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.outer-content {
  padding-top: 14px;
  padding-bottom: 10px;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.responsive-image {
  max-width: 30%;
  height: auto;
}

.project-logo-corner {
  border-radius: 20px;
}

.respect-new-lines {
  white-space: pre-wrap;
}

.display-flex {
  display: flex !important;
}

.no-caret-dropdown::after {
  display: none !important;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.copy-control input {
  height: 48px;
  padding: 16px 66px 16px 16px;
}

.copy-control .button-container {
  position: absolute;
  float: right;
  top: 13%;
  right: 32px;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
}

.btn-group-sm>.btn--icon.btn, .btn-sm.btn--icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  border-radius: 50%;
}

.a-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.line-height-compact {
  line-height: 0.9;
}

.p4, .p5 {
  line-height: 1.33333!important;
  font-size: 12px;
}

.p4 {
  font-weight: 400;
}

.a-typography {
  margin-top: 0;
  margin-bottom: 0;
}

.shareicons {
  height: 40px;
}

.absolute-lower-left {
  position: fixed;
  display: inline;
  top: 80%;
  left: 5%;
  max-width: 80% !important;
  height: 40px;
  bottom: 20%;
  z-index: 1051; /* z-index of modal is 1050 */
}

.absolute-center-left {
  position: fixed;
  display: inline;
  max-width: 80% !important;
  height: 50px;
  bottom: 20%;
  z-index: 1051; /* z-index of modal is 1050 */
}

.display-block {
  display: block !important;
}

.center-badge {
  font-size: 16px;
  vertical-align: middle;
}

.link-width {
  width: 90%;
}

.app-color-tint {
  background-color: #eef6fa;
}

#dropdown-basic::after{
  display: none;
}

.heart-icon {
  cursor: pointer;
}

.heart-filled {
  color: #F00;
}

.heart-icon:hover {
  filter: drop-shadow(0 0 1px #F00);
}

.clickable-card-elem {
  z-index: 2;
  position: relative;
}

.loading-container {
  min-height: 400px;
}

.loading-container-small {
  min-height: 200px;
}

.send-button {
  height: 45px;
}

.imber-popover {
  min-width: 400px !important;
}
